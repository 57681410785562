<template>
  <div class="card mb-4">
    <h2 class="text-center mt-3"> Successful Transactions</h2>

    <div class="card-body failed">
      <div class="form-group">
  <input type="text" class="form-control" v-model="searchTerm" placeholder="Search by Mobile,ID or Amount">
</div>
      <table class="table mt-3 table-responsive-sm">
        <thead>
          <tr>
            <th>id</th>
            <th>Mobile</th>
            <th>Amount</th>
            <th>TransID</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
           <tr v-for="(success, index) in paginatedSuccessDetails" v-bind:key="index">
            <td>{{ success.id }}</td>
            <td>{{ success.MSISDN }}</td>
            <td>{{ success.TransAmount }}</td>
            <td>{{ success.TransID }}</td>
            <td>{{ success.created_at }}</td>
            <th><span class="bg-success rounded text-white p-1">{{success.Status}}</span></th>
          </tr>
          
    
        </tbody>
        
        
      </table>
      <div class="d-flex justify-content-between">
  <button class="btn btn-nav" @click="currentPage--" :disabled="currentPage === 1">Previous</button>
  <span>{{ currentPage }} of {{ Math.ceil(filteredSuccessDetails.length / itemsPerPage) }}</span>
  <button class="btn btn-nav" @click="currentPage++" :disabled="currentPage === Math.ceil(filteredSuccessDetails.length / itemsPerPage)">Next</button>
</div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
export default {

  computed: {
 filteredSuccessDetails() {
    const filtered = this.successDetails.filter(success => {
      return success.MSISDN.includes(this.searchTerm) || success.TransID.includes(this.searchTerm) || success.TransAmount.toString().includes(this.searchTerm);
    });
    return filtered.slice().reverse();
  },

paginatedSuccessDetails() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredSuccessDetails.slice(start, end);
  }
},
  data() {
    return {
      successDetails: [],
       searchTerm: '',
    currentPage: 1,
    itemsPerPage: 15
    };
  },

  created() {
    this.successData();
  },

  methods: {
    async successData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}success`)
        .then((response) => {
          this.successDetails = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>

.failed{
    overflow-y:auto;
    max-width: 100%;
}

h2,th{
   font-family: 'Roboto Mono', monospace;
   
}

.btn-nav{
  background-color: #008080;
  color: white;
}

.btn.btn-nav:hover {

  transform: scale(1.0);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  cursor: pointer;
}


  th, td {
    font-size: smaller;
  }

</style>
